
import Vue from 'vue';

export default Vue.extend({
  name: 'InputShopReviewSuggestions',
  props: {
    rating: {
      type: Number,
      default: 0,
    },
    currentMessage: {
      type: String,
      default: '',
    },
  },
  computed: {
    suggestions(): string[] {
      const index = this.rating < 1 ? 4 : this.rating - 1;

      // @ts-ignore
      return this.$t('page.reviews.form.starRating')?.[index];
    },
  },
  methods: {
    handleClick(suggestion: string): void {
      this.$emit('select-suggestion', suggestion);
    },
  },
});
