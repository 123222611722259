var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"inline-flex gap-2"},_vm._l((_vm.starsMaxCount),function(_,idx){return _c('IcStar',{key:idx,class:[
      _vm.starSizeClasses,
      {
        'text-current': _vm.rating > idx,
        [_vm.emptyStarColor]: _vm.rating <= idx,
      }
    ],attrs:{"data-testid":"input-rating-star"},on:{"click":function($event){return _vm.handleClick(idx)}}})}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }