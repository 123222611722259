
import Vue from 'vue';

import IcCheck from '@/assets/svg/ic_check.svg?inline';
import IcCross from '@/assets/svg/ic_cross.svg?inline';
import { ERouteName } from '@/domain/core/Routes.enum';
import { ReviewToCollect } from '@/domain/review/types';
import { EGtmEventReview } from '@/infrastructure/externalServices/gtm/DataLayer.enum';
import { imagePlaceholderId } from '@/infrastructure/externalServices/imageCdn/types';

export default Vue.extend({
  name: 'BannerReviewToCollect',
  components: {
    IcCheck,
    IcCross,
  },
  data() {
    return {
      isModalOpen: false,
      isReviewSubmitted: false,
      reviews: [] as ReviewToCollect[],
      reviewsPageHref: this.$router.resolve({ name: ERouteName.AccountReviews }).href,
    };
  },
  fetchOnServer: false,
  async fetch(): Promise<void> {
    this.reviews = await this.$repositories.user.getReviewsToCollect(this.$accessor.user.id) || [];
  },
  computed: {
    firstReview(): ReviewToCollect {
      const [review] = this.reviews || [];

      return review;
    },
    firstReviewImageId(): string {
      return this.firstReview?.product?.mainPicture?.id || imagePlaceholderId;
    },
    firstReviewSellerName(): string {
      return this.firstReview?.seller?.firstName || '';
    },
    firstReviewProductSku(): string {
      return this.firstReview?.product?.sku || '';
    },
    hasReviews(): boolean {
      return !!this.reviewsCount;
    },
    reviewsCount(): number {
      return this.reviews.length;
    },
  },
  watch: {
    isModalOpen(isOpen: boolean): void {
      if (isOpen) {
        this.trackViewModal();
      }
    },
  },
  methods: {
    trackViewModal(): void {
      this.$gtm.push({
        event: EGtmEventReview.ViewModal,
        sku: this.firstReviewProductSku,
      });
    },
    closeModal(): void {
      this.isModalOpen = false;
    },
    dismissBanner(): void {
      this.reviews.splice(0); // NOTE: empty reviews array to hide banner. This will appear again after reload though.
    },
    handleSubmitReview(): void {
      this.isReviewSubmitted = true;
      this.closeModal();
    },
    openModalAndPrefillRating(rating: ReviewToCollect['rating']): void {
      this.isModalOpen = true;

      this.reviews[0].rating = rating; // NOTE: prefill rating for the first review.
    },
    trackClickMultiReviewsLink(): void {
      this.$gtm.push({ event: EGtmEventReview.ClickRateButton });
    },
  },
});
