
import Vue, { PropType } from 'vue';

import IcStar from '@/assets/svg/ic_star.svg?inline';
import { ReviewToCollect } from '@/domain/review/types';
import { EGtmEventReview } from '@/infrastructure/externalServices/gtm/DataLayer.enum';

const starsMaxCount = 5;

export default Vue.extend({
  name: 'InputRatingStars',
  components: {
    IcStar,
  },
  props: {
    emptyStarColor: {
      type: String,
      default: 'text-gray',
    },
    rating: {
      type: Number,
      default: 0,
    },
    starSizeClasses: {
      type: String,
      default: 'size-5',
    },
    productSku: {
      type: String as PropType<ReviewToCollect['product']['sku']>,
      default: '',
    },
  },
  data() {
    return {
      starsMaxCount,
    };
  },
  methods: {
    handleClick(starIndex: number): void {
      const rating = starIndex + 1;

      this.$emit('update:rating', rating);
      this.trackClickRatingStar(rating);
    },
    trackClickRatingStar(rating: ReviewToCollect['rating']): void {
      this.$gtm.push({
        event: EGtmEventReview.ClickRatingStar,
        star: rating,
        sku: this.productSku,
      });
    },
  },
});
