import { ECountryCodeISO31661 } from '../../../domain/core/Country.enum';
import { ELanguage, LanguageISO } from '../../../domain/core/Language.enum';

export const alternateLinksConfig = [
  {
    hreflang: LanguageISO.FR.FR,
    countryCode: ECountryCodeISO31661.FR,
    lang: ELanguage.FR,
  },
  {
    hreflang: LanguageISO.EN.GB,
    countryCode: ECountryCodeISO31661.GB,
    lang: ELanguage.EN,
  },
  {
    hreflang: LanguageISO.EN.NL,
    countryCode: ECountryCodeISO31661.NL,
    lang: ELanguage.EN,
  },
];
