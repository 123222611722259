
import Vue, { PropType } from 'vue';
import { TranslateResult } from 'vue-i18n';

import IcCheck from '@/assets/svg/ic_check.svg?inline';
import { ReviewToCollect } from '@/domain/review/types';
import { EGtmEventReview } from '@/infrastructure/externalServices/gtm/DataLayer.enum';
import { imagePlaceholderId } from '@/infrastructure/externalServices/imageCdn/types';

export default Vue.extend({
  name: 'FormShopReview',
  components: {
    IcCheck,
  },
  props: {
    review: {
      type: Object as PropType<ReviewToCollect>,
      required: true,
    },
  },
  data() {
    return {
      comment: '' as ReviewToCollect['comment'],
      errorMessage: null as TranslateResult | null,
      isLoading: false,
      isReviewSubmitted: false,
      messageCharactersCountThreshold: {
        max: 1000,
        min: 3,
      },
      rating: this.review.rating || 0 as ReviewToCollect['rating'],
    };
  },
  computed: {
    commentLength(): number {
      return this.comment?.length || 0;
    },
    imageAlt(): string {
      return this.review?.product?.title;
    },
    imageSource(): string {
      return this.review?.product?.mainPicture?.id || imagePlaceholderId;
    },
    sku(): string {
      return this.review?.product?.sku || '';
    },
  },
  methods: {
    handleRatingUpdate(value: number): void {
      this.rating = value;
    },
    handleSelectSuggestion(suggestion: string): void {
      this.comment = suggestion;

      this.trackClickOnSuggestion(suggestion);
    },
    async handleSubmit(): Promise<void> {
      this.errorMessage = null;

      if (!this.rating) {
        this.errorMessage = this.$t('page.reviews.form.error.noRating');

        return;
      }

      try {
        this.isLoading = true;

        await this.$repositories.review.update({
          id: this.review.id,
          rating: this.rating,
          comment: this.comment,
        });

        this.trackSubmit();

        this.$emit('submit-review');
        this.isReviewSubmitted = true;
      } catch (err) {
        this.errorMessage = this.$t('form.common.errors.generic');

        this.$errorMonitor.report(err, 'error');
      } finally {
        this.isLoading = false;
      }
    },
    trackSubmit(): void {
      this.$gtm.push({
        event: EGtmEventReview.Submit,
        value: this.comment,
        star: this.rating,
        id: this.review.id,
        sku: this.review.product.sku,
      });
    },
    trackClickOnSuggestion(suggestion: string): void {
      this.$gtm.push({
        event: EGtmEventReview.ClickSuggestion,
        value: suggestion,
        sku: this.review.product.sku,
      });
    },
  },
});
